const questions = [
  // 1 Слайд \\ Перу [1]
  {
    questionText:
      "Перу — горная страна, где культуры коренных жителей и европейских переселенцев переплетаются в самобытный узор. А еще… ",
    answerOptions: [
      {
        answerText:
          "Это третья по площади страна Южной Америки после Бразилии и Аргентины.",
        answerResult: "Верно! Площадь этой страны составляет 1 285 000 км².",
        result: true,
      },
      {
        answerText:
          "Диких лам здесь удалось приручить только в середине XX века.",
        answerResult:
          "Вовсе нет. Ламы стали домашними животными 3000+ лет назад.",
        result: false,
      },
      {
        answerText:
          "Национальный головной убор перуанцев — вязаная шапочка с наушниками — называется кипу.",
        answerResult:
          "А вот и нет. Кипу — это древний способ узелкового письма инков. А шапочка называется чульо.",
        result: false,
      },
      {
        answerText:
          "В пустыне Наска расположены загадочные пирамиды — памятник древней цивилизации.",
        answerResult:
          "В пустыне Наска и правда древние перуанцы оставили свой след. Только это вовсе не пирамиды, а геоглифы — гигантские рисунки на земле.",
        result: false,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд \\ Аргентина [4]

  {
    questionText:
      "В путешествии по Аргентине можно увидеть и диких обезьян в северных джунглях, и пингвинов на морозном южном побережье. Угадай, что из этого правда.",
    answerOptions: [
      {
        answerText:
          "Название столицы Аргентины в переводе с испанского значит «красивые цветы».",
        answerResult:
          "Столица Аргентины — Буэнос-Айрес, что значит «чистый воздух».",
        result: false,
      },
      {
        answerText:
          "Сборная Аргентины по футболу чаще других становилась чемпионом мира.",
        answerResult:
          "К сожалению, для аргентинцев, их сборная лишь трижды завоевывала трофей. Больше всего побед у Бразилии — пять.",
        result: false,
      },
      {
        answerText: "Большинство аргентинцев — вегетарианцы.",
        answerResult:
          "С точностью до наоборот. Аргентина занимает первое место на планете по потреблению мяса. Это основа национальной кухни.",
        result: false,
      },
      {
        answerText:
          "Традиционный чай мате аргентинцы пьют через трубочку из специальных сосудов, которые называются калабасы.",
        answerResult: "Именно так! А трубочка с ситечком называется бомбилья.",
        result: true,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд \\ Вьетнам [1]
  {
    questionText:
      "На побережье Южно-Китайского моря с севера на юг протянулась эта страна джунглей и больших городов. Расскажу несколько фактов о ней.",
    answerOptions: [
      {
        answerText: "На юге Вьетнама есть небольшая пустыня.",
        answerResult:
          "Это так. Можно погонять по песчаным холмам на квадроцикле, а можно просто скатиться с них, как с горки.",
        result: true,
      },
      {
        answerText:
          "Вьетнам занимает первое место в мире по производству кофе.",
        answerResult:
          "Обойти страну по производству кофе смогла лишь Бразилия. Так что у Вьетнама почетное второе место.",
        result: false,
      },
      {
        answerText:
          "Вьетнамские деньги — донги — сделаны из ткани для большей износостойкости.",
        answerResult:
          "Донги и впрямь не рвутся, не пачкаются и не мокнут. Правда, сделаны они из пластика.",
        result: false,
      },
      {
        answerText:
          "Знаменитый вьетнамский суп «Фо Бо» пришел к нам из глубокой древности.",
        answerResult:
          "Не совсем так. Говядину, из которой готовят бульон для лапши, вьетнамцы стали употреблять по примеру европейцев в XIX веке. До этого крупный рогатый скот использовался только для пахоты и перевозки грузов.",
        result: false,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд \\ Малайзия [3]
  {
    questionText:
      "Малайзия входит в десятку самых посещаемых туристами стран. Вот еще несколько утверждений об этой стране. Но лишь одно из них — правда.",
    answerOptions: [
      {
        answerText:
          "По территории острова Калимантан проходит граница между двумя государствами.",
        answerResult:
          "Между тремя. Части этого острова принадлежат не только Индонезии и Малайзии, но и государству Бруней.",
        result: false,
      },
      {
        answerText: "В Малайзии произрастает до 50 сортов яблок.",
        answerResult:
          "Несмотря на то, что в Малайзии можно встретить самые причудливые фрукты, обычные яблоки здесь не растут, они всегда импортные.",
        result: false,
      },
      {
        answerText: "За проезд по городу на слоне здесь предусмотрен штраф.",
        answerResult:
          "Это так. Да и куда удобнее прокатиться на авто или мотоцикле, которых в Малайзии очень много.",
        result: true,
      },
      {
        answerText:
          "В Малайзии обитают самые маленькие медведи в мире — бируанги. Размером они со среднюю собаку.",
        answerResult:
          "Так, да не так. Бируанг действительно самый маленький медведь, но ростом бывает до полутора метров, а весом до 65 килограммов.",
        result: false,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд \\ Россия [4]
  {
    questionText:
      "Ну, это должно быть просто! В своем кругосветном путешествии я проехал почти всю Россию и узнал много интересных фактов. Угадаешь, что из этого придумал?",
    answerOptions: [
      {
        answerText:
          "Площадь России приблизительно равна площади поверхности планеты Сатурн.",
        answerResult:
          "Наша страна, конечно, большая, но Сатурн — настоящий гигант. Площадь России — больше 17 000 000 км². Это сравнимо с площадью поверхности Плутона.",
        result: false,
      },
      {
        answerText: "Самое глубокое метро в мире — московское.",
        answerResult:
          "Ошибочка с городом. Лидером в топе самых глубоких станций стала «Адмиралтейская»: она находится в Санкт-Петербурге и лежит на 86 метров ниже уровня моря.",
        result: false,
      },
      {
        answerText:
          "Расстояние от Владивостока до Калининграда больше 20 000 км",
        answerResult:
          "В половину меньше — около 10 000 км. На машине это расстояние можно проехать за 117 часов! В «Максиме» почти побили этот рекорд: в 2016 году семья заказала поездку из Ставропольского края в Уссурийск. Длина маршрута составила 9257 км!",
        result: false,
      },
      {
        answerText: "В России есть свои 7 чудес.",
        answerResult:
          "А вот это правда! Чудеса выбрали жители страны в 2008 году. Среди них, например, долина Гейзеров на Камчатке, озеро Байкал, гора Эльбрус, Петергофские фонтаны, Мамаев курган и Родина-мать.",
        result: true,
      },
    ],
    image: "images/05.webp",
  },

  // 6 слайд \\ Бразилия [2]
  {
    questionText:
      "Самба, анаконда, капоэйра — все это есть в покрытой тропическими лесами Бразилии. А чем еще знаменита эта страна?",
    answerOptions: [
      {
        answerText:
          "Столица Бразилии — Сан-Паулу. Этот город спроектирован архитектором Лусио Коста в форме бабочки или летящей птицы.",
        answerResult:
          "Столица в плане и впрямь напоминает птицу или даже самолет. Но называется этот город — Бразилиа.",
        result: false,
      },
      {
        answerText:
          "Сахарного тростника в Бразилии так много, что из него даже делают топливо, способное заменить нефтепродукты.",
        answerResult:
          "Верно! Бразилия — мировой лидер по выращиванию сахарного тростника и апельсинов.",
        result: true,
      },
      {
        answerText: "Знаменитый бразильский карнавал длится целый месяц.",
        answerResult:
          "Как бы ни хотелось продлить праздник местным жителям и туристам, но карнавал длится всего пять дней. За ним следует Великий пост.",
        result: false,
      },
      {
        answerText:
          "Бразильцы — непревзойденные футболисты. Еще бы, ведь упоминание этой игры есть даже в их национальном гимне.",
        answerResult:
          "Ну нет. В тексте гимна Бразилии говорится в основном о любви к Родине, славном прошлом и светлом будущем.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },

  // 7 слайд \\ Индонезия [3]
  {
    questionText:
      "Страна состоит из 17 500 островов! Потребуется примерно 12 часов, чтобы пересечь ее на самолете. Это — Индонезия.",
    answerOptions: [
      {
        answerText:
          "На территории Индонезии находится девять объектов Всемирного наследия ЮНЕСКО. Все они природные.",
        answerResult:
          "Среди этих объектов природных только половина. Остальные созданы человеком, например храмовые комплексы Боробудур и Прамбанан.",
        result: false,
      },
      {
        answerText: "Индонезийцы любят поспать.",
        answerResult:
          "Может и так, но большинство встает очень рано, часто до 5:00. С утра можно совершить молитву, успеть на работу до пробок или отправиться на учебу.",
        result: false,
      },
      {
        answerText: "Индонезия — страна мототакси.",
        answerResult:
          "Это факт. Из-за интенсивного трафика этот маневренный вид транспорта очень популярен.",
        result: true,
      },
      {
        answerText:
          "Кажется, что в Индонезии кошки повсюду, но на самом деле их популяция составляет не более одного миллиона особей.",
        answerResult:
          "Миллион кошек для такой огромной страны — это слишком мало. Кошек здесь больше 30 миллионов. Это без деревянных с острова Бали!",
        result: false,
      },
    ],
    image: "images/07.webp",
  },

  // 8 слайд \\ Мексика [2]
  {
    questionText:
      "Что ты представляешь, когда думаешь о Мексике? Наверное, людей в сомбреро и пустыню, усеянную кактусами. Так ли это справедливо? Давай проверим!",
    answerOptions: [
      {
        answerText:
          "В столице Мексики нет такси, но зато развит общественный транспорт.",
        answerResult:
          "Напротив. Именно Мехико занимает первое место в мире по количеству машин такси. Здесь их больше ста тысяч.",
        result: false,
      },
      {
        answerText:
          "Попкорн изобрели в Мексике больше четырех тысяч лет назад.",
        answerResult:
          "Верно. Индейцы нагревали особый вид кукурузы и использовали раскрывшиеся зерна в пищу или для украшения.",
        result: true,
      },
      {
        answerText:
          "Один из символов Мексики — собачек породы чихуахуа — завезли испанские конкистадоры, которые использовали их для ловли крыс на кораблях.",
        answerResult:
          "Чихуахуа на все сто процентов мексиканцы. Эти отважные крохи произошли от вымершей породы течичи, которую мексиканские племена считали священной.",
        result: false,
      },
      {
        answerText:
          "Кактусы в Мексике используются только для приготовления текилы.",
        answerResult:
          "Конечно, нет. Разные сорта кактусов употребляют в пищу, готовят из них лекарства, шампуни, красители и даже ткань.",
        result: false,
      },
    ],
    image: "images/08.webp",
  },
];

export default questions;
